@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: lato, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-hero{
  font-family: origin-super-condensed, sans-serif;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 3px;
}
.font-headline {
  font-family: myriad-pro-condensed, sans-serif;
  font-weight: 700;
  font-style: normal;
}
.font-btn {
  font-family: lato, sans-serif;
  font-weight: 900;
  font-style: normal;
}
