
$bg: #0A5ED5;
$btn: #F4B400;
$btn-alt: #1DA1F2;
$pop: #DB4437;
$gray: #929494;

.App {
  text-align: center;
  background-color: $bg;
  a{
    color: $btn;
    text-decoration: none !important;
  }
  header{
    position: relative;
    padding: 120px 50px;
    overflow: hidden;
    display: flex;
    justify-self: center;
    align-items: center;
    video{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(54, 5, 87, 0.4);
      z-index: 1;
    }
    h1.main{
      position: relative;
      z-index: 3;
      color: $pop;
      text-shadow: 3px 3px 0px $btn;
      font-family: korolev-condensed, sans-serif;
      font-size: 140px;
      line-height: 1;
    }

  }
  .btn-color{
    background-color: $btn;
    color: black;
    cursor: pointer;
    box-shadow: 3px 3px 0px $pop;
    &:hover{
      background-color: $pop;
    }
  }
  .btn-color-alt{
    background-color: $btn-alt;
    cursor: pointer;
    &:hover{
      background-color: $pop;
    }
  }
  ul{
    text-align: left;
    list-style: disc;
    padding-left: 30px;
    li{
      margin-bottom: 20px;

    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.common-safe-span,
.common-wp-content {
  p {
    margin-bottom: 1.7rem;
  }
  a{
    text-decoration: underline;
  }
}
.below-form h2,
.common-wp-content h2 {
  font-family: korolev-condensed, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.1;
}
.common-wp-content h2 {
  // margin-top: 50px;
  margin-bottom: 20px;
  .alt{
    color: $btn;
  }
}
.common-wp-content h2 em{
  font-style: normal;
  color: #23E9DA;
}
.below-form h2{
  // color: #CDFF2D;
  margin: 40px 0;
}

@media (max-width: 768px) {
  .App{
    .below-form h2,
    .common-wp-content h2 {
      font-size: 2.4rem;
    }
    header{
      padding: 50px 20px;
      h1.main{
        font-size: 70px;;
      }
    }
  }
}